// var env                                     = "dev-" // Dev
var env                                     = "" // Pro


// web
export const http                           = "https://"
export const head                           = http + env
export const taobaoApi                      ="taobao2you.com/"
export const JJCargoApi                     ="sabuyjaicargo.com/"

// service dotnet TaoBao
export const taobaoService_dotnet           = head + "api." + taobaoApi

// service dotnet JJ
export const jjCargoService_dotnet          = head + "api." + JJCargoApi

// service dotnet JJ localhost
// export const jjCargoService_dotnet          = "https://localhost:5001/"

