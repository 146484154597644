export function format_number(number) {
    return (Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')).replace('.00', '')
}

export function format_price(price) {
    return Number(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function format_decimal(price) {
  return Number(price).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function formatDatetime (datetime) {
    if (datetime==null || datetime==""){
      return null
    }
    return datetime.substring(8,10)+"-"+datetime.substring(5,7)+"-"+datetime.substring(0,4)+" "+datetime.substring(11,16)
  }

  export function formatDate_thai (datetime) {
    if (datetime==null || datetime==""){
      return null
    }
    return datetime.substring(8,10)+"/"+datetime.substring(5,7)+"/"+datetime.substring(0,4)
  }

  export function formatDate_thaiShort (datetime) {
    if (datetime==null || datetime==""){
      return null
    }
    return datetime.substring(8,10)+"/"+datetime.substring(5,7)+"/"+datetime.substring(2,4)
  }

  export function formatDate_eng (datetime) {
    if (datetime==null || datetime==""){
      return null
    }
    return datetime.substring(0,4)+"-"+datetime.substring(5,7)+"-"+datetime.substring(8,10)
  }

  export function formatDate_engTime (datetime) {
    if (datetime==null || datetime==""){
      return null
    }
    return datetime.substring(0,4)+"-"+datetime.substring(5,7)+"-"+datetime.substring(8,10)+" "+datetime.substring(11,16)
  }

  export function formatDate_dmy (datetime) {
    if (datetime==null || datetime==""){
      return null
    }
    return datetime.substring(8,10)+"-"+datetime.substring(5,7)+"-"+datetime.substring(0,4)
  }
  export function formatDate_thaiToEng (datetime) {
    if (datetime==null || datetime==""){
      return null
    }
    return datetime.substring(6,10)+"-"+datetime.substring(3,5)+"-"+datetime.substring(0,2)
  }

export async function setFileBase64(p_file) {
  var fileBase64 = null
  const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });
  if (p_file!=null && p_file!=undefined) {
    fileBase64 = await toBase64(p_file)
  }
  return fileBase64
}

export function isNumberWithDot (evt) {
  evt = (evt) ? evt : window.event
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    evt.preventDefault()
  } else {
    return true
  }
}

export function isNumberWNoDot (evt) {
  evt = (evt) ? evt : window.event
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
    evt.preventDefault()
  } else {
    return true
  }
}

export function isEnglishlanguage (evt) {
  evt = (evt) ? evt : window.event
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) || charCode === 46) {
    evt.preventDefault()
  } else {
    return true
  }
}

export function isNumberWitMark (evt) {
  evt = (evt) ? evt : window.event
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 45) {
    evt.preventDefault()
  } else {
    return true
  }
}
