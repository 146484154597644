<template>
  <div class="pb-2">
    <!-- srch box -->
    <b-card no-body>
      <b-card-body class="pb-0">
        <b-row>
          <b-col class="col-12 pb-1">
            <div class="d-md-flex align-items-center">
              <span class="col-md-2 px-0">วันที่: </span>
              <flat-pickr v-model="date" class="form-control col-md-2" :config="datePickerConfig"/>
              <div class="mx-1">-</div>
              <flat-pickr v-model="dueDate" class="form-control col-md-2" :config="datePickerConfig"/>
            </div>
          </b-col>
          <b-col class="col-12 pb-1">
            <div class="d-md-flex align-items-center">
              <span class="col-md-2 px-0">ช่องทางชำระ: </span>
              <b-form-radio-group
                v-model="statusId"
                :options="statusList"
                text-field="text"
              >
              </b-form-radio-group>
            </div>
          </b-col>
          <b-col class="col-12 pt-1">
            <div class="d-md-flex align-items-center">
              <span class="col-md-2 px-0"></span>
              <b-button variant="primary" @click="getShipmentList()">
                ค้นหา
              </b-button>
            </div>
          </b-col>
        </b-row>
        <hr class="mt-2">
      </b-card-body>
      <div v-if="this.loadpage == false" class="text-center pt-3">
        <b-spinner
          style="width: 5rem; height: 5rem;"
          variant="primary"
          label="Large Spinner"
        />
      </div>
      <b-card-body v-else>
        <div class="text-right pb-1">จำนวนทั้งหมด : {{this.totalRows}}</div>
        <!-- table -->
        <b-table
          responsive
          :items="shipmentList"
          :fields="shipmentFields"
          table-class="table-md-responsive text-nowrap"
        >
         <template #cell(status)="data">
            <div v-if="data.value == 'ถึงโกดังไทย'" class="text-success">{{data.value}}</div>
            <div v-else-if="data.value == 'รับเข้าโกดังจีน'" class="text-dark">{{data.value}}</div>
            <div v-else class="text-warning">{{data.value}}</div>
          </template>
          <template #cell(totalBags)="data">
            {{format_number(data.value)}}
          </template>
          <template #cell(sumWeight)="data">
            {{format_price(data.value)}}
          </template>
          <template #cell(receiveDate)="data">
            {{data.value.slice(0, -3)}}
          </template>
          <template #cell(shipmentDate)="data">
            {{data.value.slice(0, -3)}}
          </template>
          <template #cell(arrivedDate)="data">
            {{data.value.slice(0, -3)}}
          </template>
          <template #cell(shipmentID)="data">
            <span>
              <feather-icon
                :id="`view-${data.value}`"
                icon="FileTextIcon"
                class="cursor-pointer text-primary"
                size="20"
                @click="toShipmentDetail(data.value, data.item.shipmentcode)"
              />
              <b-tooltip
                title="ดูข้อมูล"
                :target="`view-${data.value}`"
                :delay="{show: '1000'}"
              />
            </span>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BForm, BFormGroup, BFormInput,
  BSpinner, BButton, BTable, BTooltip, BBadge, BPagination, BFormRadioGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
import { header_token } from '@/views/token'
import { format_price, format_number, formatDatetime } from '@/views/global_function.js'
import {jjCargoService_dotnet} from '@/views/global.js'

export default {
  name: 'JJCargoFrontendProductStatus',

  components: {
    BCard, BCardHeader, BCardBody, BRow, BCol, BForm, BFormGroup, BFormInput,
    BSpinner, BButton, BTable, BTooltip, BBadge, BPagination, BFormRadioGroup,
    vSelect,
    flatPickr
  },

  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      date: null,
      dueDate: null,
      datePickerConfig: {
        altInput: true,
        altFormat: "d-m-Y",
        altLanguage: "th"
      },

      statusId: 0,
      statusList: [
        {text: 'ทั้งหมด', value: 0},
        {text: 'รับเข้าโกดังจีน', value: 1},
        {text: 'ระหว่างขนส่ง', value: 2},
        {text: 'ถึงโกดังไทย', value: 3},
      ],

      shipmentList: [],
      shipmentFields: [
        { key: 'status', label: 'สถานะ', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'shipmentcode', label: 'เลขตู้', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'receiveDate', label: 'วันที่รับของ', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'shipmentDate', label: 'วันที่ส่งออก', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'arrivedDate', label: 'วันที่ถึงไทย', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'totalBags', label: 'จำนวน', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'sumWeight', label: 'น้ำหนัก', thClass: 'text-center', tdClass: 'text-right' },
        { key: 'sumArea', label: 'ขนาด', thClass: 'text-center', tdClass: 'text-right' },
        { key: 'shipmentID', label: 'รายละเอียด', thClass: 'text-center', tdClass: 'text-center' },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,

      loadpage: true
    };
  },

  async created() {
    this.getShipmentList()
  },

  watch: {
    async currentPage() {
      await this.getShipmentList()
    }
  },

  methods: {
    format_price, format_number, formatDatetime,

    async getShipmentList() {
      this.loadpage = false
      var skip = 0
      skip = (this.currentPage - 1) * 10
      let response = await axios.post(
        jjCargoService_dotnet + 'Shipment/get-shipment-list',
        {
          "customerID": JSON.parse(localStorage.getItem('cusId')),
          "dateFrom": this.date,
          "dateTo": this.dueDate,
          "statusID": this.statusId,
          "skip": skip,
          "take": 10
        },
        { headers: header_token }
      );
      this.totalRows = response.data.resultCount
      this.shipmentList = response.data.items
      this.loadpage = true
    },

    toShipmentDetail(id, num) {
      window.open('/shipment-detail?id=' + id + '&num=' + num);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>

</style>